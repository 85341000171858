import React from 'react'

const CheckRoundedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.5 2C7.31331 2 6.15328 2.35189 5.16658 3.01118C4.17989 3.67047 3.41085 4.60754 2.95673 5.7039C2.5026 6.80026 2.38378 8.00666 2.61529 9.17054C2.8468 10.3344 3.41825 11.4035 4.25736 12.2426C5.09648 13.0818 6.16558 13.6532 7.32946 13.8847C8.49335 14.1162 9.69975 13.9974 10.7961 13.5433C11.8925 13.0892 12.8295 12.3201 13.4888 11.3334C14.1481 10.3467 14.5 9.18669 14.5 8C14.5 6.4087 13.8679 4.88258 12.7426 3.75736C11.6174 2.63214 10.0913 2 8.5 2ZM11.1667 6.74667L7.96 9.95333C7.91407 10.0004 7.85908 10.0376 7.79835 10.0628C7.73762 10.088 7.67242 10.1007 7.60667 10.1C7.54098 10.1003 7.47588 10.0875 7.41521 10.0623C7.35453 10.0371 7.2995 10.0001 7.25334 9.95333L5.83334 8.49333C5.77241 8.39936 5.7448 8.28766 5.75492 8.17612C5.76505 8.06458 5.81232 7.95968 5.88918 7.87821C5.96603 7.79675 6.06801 7.74345 6.17876 7.72685C6.28952 7.71025 6.40264 7.73131 6.5 7.78667L7.60667 8.89333L10.5 6.04C10.5974 5.98465 10.7105 5.96358 10.8212 5.98018C10.932 5.99678 11.034 6.05008 11.1108 6.13155C11.1877 6.21301 11.235 6.31792 11.2451 6.42945C11.2552 6.54099 11.2276 6.6527 11.1667 6.74667Z"
      fill="#52B788"
    />
  </svg>
)

export default CheckRoundedIcon
