import classNames from 'classnames'
import React from 'react'

import {
  JOBSTATUS_OPTIONS,
  JOB_STATUS,
  JOB_STATUS_ENUM,
} from '@constants/job-statuses'

type JobStatusBadgeProps = {
  jobStatus: string
}

const JobStatusBadge: React.FC<JobStatusBadgeProps> = ({
  jobStatus,
}: JobStatusBadgeProps) => {
  let statusBadgeBackgroundColor = ''
  let statusBadgeTextColor = ''

  switch (jobStatus) {
    case JOB_STATUS.ACTIVE:
      statusBadgeBackgroundColor = 'bg-green-50'
      statusBadgeTextColor = 'text-green-700'
      break
    case JOB_STATUS.INACTIVE:
      statusBadgeBackgroundColor = 'bg-border-gray'
      statusBadgeTextColor = 'text-gray-500'
      break
    case JOB_STATUS.EXPIRED:
      statusBadgeBackgroundColor = 'bg-[#FFE3E0]'
      statusBadgeTextColor = 'text-[#CC444B]'
      break
    default:
      statusBadgeBackgroundColor = 'bg-border-gray'
      statusBadgeTextColor = 'text-gray-500'
      break
  }

  return (
    <div
      className={classNames(
        'flex flex-none items-center justify-between rounded-3xl px-4',
        statusBadgeBackgroundColor
      )}
    >
      <span className={classNames(statusBadgeTextColor)}>
        {
          JOBSTATUS_OPTIONS.find((opt) =>
            opt.value.includes(jobStatus as JOB_STATUS_ENUM)
          )?.name
        }
      </span>
    </div>
  )
}

export default JobStatusBadge
