import parse from 'html-react-parser'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'

import Button from '@components/button'
import Drawer from '@components/drawer/Drawer'
import Switch from '@components/form/switch/Switch'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one'
import H3 from '@components/typography/heading-three'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { DrawerSize } from '@constants/drawer'
import { EMPLOYMENT_TYPE_OPTIONS } from '@constants/employment-type'
import { GENDER_OPTIONS } from '@constants/genders'
import { KYC_STATUS } from '@constants/kyc-status'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { GET_DEGREES_URL } from '@constants/url'

import { formatDateInID } from '@utils/date-calculation'

import CheckRoundedIcon from '@common/icons/CheckRoundedIcon'
import CloseIcon from '@common/icons/CloseIcon'

import useJobDetail from '../../hooks/job-detail'
import { jobActions } from '../../reducers'
import ExpiredDateBanner from '../expired-date-banner'
import JobStatusBadge from '../job-status-badge'
import type { JobDetailHeaderProps, JobDetailInfoProps, Props } from './type'

const JobDetailHeader: React.FC<JobDetailHeaderProps> = ({
  title,
  imageSrc,
}) => (
  <div className="flex items-center">
    <Image src={imageSrc} height={24} width={24} alt="img" />
    <div className="ml-2">
      <H3>{title}</H3>
    </div>
  </div>
)

const JobDetailInfo: React.FC<JobDetailInfoProps> = ({
  title,
  content,
  children,
}: JobDetailInfoProps) => (
  <div className="my-3 flex flex-1">
    <div className="flex-1">
      <Caption>{title}</Caption>
    </div>
    <div className="flex flex-1 space-x-4">
      {content && <p className="line-clamp-2 flex-wrap">{content}</p>}
      {children}
    </div>
  </div>
)

const MAX_TAGS = 10
const MIN_TAGS = 5

const Slider = ({ id, isOpen, onClose, changeJobStatus }: Props) => {
  const router = useRouter()
  const { jobId } = router.query
  const dispatch = useAppDispatch()
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [showedTag, setShowedTag] = useState<number>(5)
  const userType = localStorage.getItem('userType')
  const isPs = userType === 'ps'
  const { jobDetailData } = useJobDetail(
    jobId ? (jobId as string) : (id as string)
  )

  const profile = useAppSelector((state) => state.profileReducer)

  const { data: degreesData } =
    useSWRImmutable<{ id: string; degreeName: string }[]>(GET_DEGREES_URL)
  const degreeName = degreesData?.filter(
    (item) => item.id === jobDetailData?.minimumEducationDegreeId
  )[0]?.degreeName
  const handleShowButton = () => {
    if (!showDetail) {
      setShowedTag(MAX_TAGS)
    } else {
      setShowedTag(MIN_TAGS)
    }

    setShowDetail((prevState) => !prevState)
  }

  const renderTag = () => {
    if (!jobDetailData) return null
    return (
      <div className="flex w-full flex-wrap">
        {jobDetailData?.attributeTags.slice(0, showedTag).map((data) => (
          <div
            key={data?.tagName}
            className="mb-2 mr-2 inline-block rounded-3xl border border-[#A792D8] bg-[#F1EBFF] px-2 text-sm text-[#7B63B4]"
          >
            {data.tagName}
          </div>
        ))}
        {jobDetailData?.attributeTags.length > MIN_TAGS && (
          <button
            type="button"
            onClick={handleShowButton}
            className="cursor-pointer text-sm text-[#4460AC] underline"
          >
            {showDetail ? 'Lihat lebih sedikit' : 'Lihat selengkapnya'}
          </button>
        )}
      </div>
    )
  }

  const renderJobStatus = () => (
    <div className="mb-6 mt-4 flex w-full items-center justify-between">
      <Caption>Status</Caption>
      <div className="flex w-1/2 justify-start">
        <JobStatusBadge jobStatus={jobDetailData?.status || ''} />
        {jobDetailData?.status === 'Active' ||
        jobDetailData?.status === 'InActive' ? (
          <div className="ml-4">
            <Switch
              onChange={() => {
                if (
                  profile.kycStatus !== KYC_STATUS.APPROVED ||
                  profile.company?.kycStatus !== KYC_STATUS.APPROVED
                ) {
                  dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
                  return true
                }

                if (jobDetailData?.status === 'Active') {
                  dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
                  dispatch(jobActions.setModalId(jobDetailData.id))
                  onClose()
                } else {
                  changeJobStatus(
                    jobId ? (jobId as string) : (id as string),
                    jobDetailData.status
                  )
                }
                return true
              }}
              value={jobDetailData?.status === 'Active'}
            />
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <Drawer
      title={
        <button
          className="ml-auto"
          type="button"
          onClick={() => {
            onClose()
          }}
        >
          <CloseIcon className="text-primary-gray" width={20} height={20} />
        </button>
      }
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      size={DrawerSize.MEDIUM}
    >
      <div className="flex justify-between border-b pb-3">
        <H1 className="max-">{jobDetailData?.title}</H1>
        {!isPs && (
          <div className="w-[120px]">
            <Button
              appearance="outline-primary"
              type="button"
              className="h-[44px] w-[120px]"
              onClick={() => {
                onClose()
                router.push({
                  pathname: PATHS.EDIT_JOB,
                  query: {
                    jlid: jobDetailData?.id,
                  },
                })
              }}
            >
              Edit Loker
            </Button>
          </div>
        )}
      </div>
      <div className="border-b border-gray-100 pb-4">
        {!isPs && renderJobStatus()}
        <ExpiredDateBanner jobExpiredDate={jobDetailData?.expiryAt || ''} />
        {jobDetailData?.postedAt && (
          <JobDetailInfo
            title="Waktu posting"
            content={
              jobDetailData
                ? formatDateInID(jobDetailData.postedAt, 'dd MMMM yyyy, HH.mm')
                : '-'
            }
          />
        )}
      </div>
      <div className="border-b border-gray-100 py-4">
        <JobDetailHeader
          title="Informasi utama"
          imageSrc="/assets/icons/ic_basic_information.svg"
        />
        <JobDetailInfo
          title="Tipe pekerjaan"
          content={jobDetailData?.jobProfiles?.[0]?.jobProfileName}
        />
        <JobDetailInfo
          title="Kebutuhan karyawan"
          content={`${jobDetailData?.openings} karyawan`}
        />
        <JobDetailInfo
          title="Lokasi pekerjaan"
          content={jobDetailData?.location?.locationAddress}
        />
        <JobDetailInfo
          title="Jenis pekerjaan "
          content={
            EMPLOYMENT_TYPE_OPTIONS.find(
              (opt) => opt.value === jobDetailData?.employmentType
            )?.label
          }
        >
          {jobDetailData?.estimatedJobDurationInDays !== 0 &&
          jobDetailData?.estimatedJobDurationInDays ? (
            <Caption>
              ({jobDetailData?.estimatedJobDurationInDays} hari)
            </Caption>
          ) : null}
        </JobDetailInfo>
        <JobDetailInfo title="Gaji (per bulan)">
          <div className="flex flex-wrap space-x-1">
            <NumberFormat
              fixedDecimalScale={false}
              prefix="Rp. "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              displayType="text"
              value={jobDetailData?.minimumSalary}
              className="border-0 px-0 py-0"
            />
            <p className="px-1">-</p>
            <NumberFormat
              fixedDecimalScale={false}
              prefix="Rp."
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              displayType="text"
              value={jobDetailData?.maximumSalary}
              className="border-0 px-0 py-0"
            />
          </div>
        </JobDetailInfo>
        <JobDetailInfo title="Deskripsi pekerjaan">
          <div>{parse(jobDetailData?.description || '-')}</div>
        </JobDetailInfo>
      </div>

      <div className="py-4">
        <JobDetailHeader
          title="Persyaratan"
          imageSrc="/assets/icons/ic_requirements.svg"
        />
        <JobDetailInfo title="Keahlian yang dibutuhkan">
          {jobDetailData?.attributeTags.length !== 0 && renderTag()}
        </JobDetailInfo>

        <JobDetailInfo title="Pendidikan terakhir" content={degreeName} />
        <JobDetailInfo
          title="Pengalaman kerja"
          content={`${
            jobDetailData?.requiredExperienceInYears !== 0
              ? jobDetailData?.requiredExperienceInYears
              : '<1'
          } tahun`}
        />

        <JobDetailInfo title="Usia">
          {jobDetailData?.minimumAge ? (
            <div className="flex">
              <p>{jobDetailData?.minimumAge} tahun</p>
              {jobDetailData?.maximumAge && (
                <>
                  <p className="px-1">-</p>
                  <p>{jobDetailData?.maximumAge} tahun</p>
                </>
              )}
            </div>
          ) : (
            <p>-</p>
          )}
        </JobDetailInfo>
        <JobDetailInfo
          title="Jenis kelamin"
          content={
            GENDER_OPTIONS.find(
              (opt) => opt.value === jobDetailData?.requiredGender
            )?.label
          }
        />
        <JobDetailInfo
          title="Dokumen untuk asesmen lanjutan"
          content={`${jobDetailData?.isCVRequired ? 'CV' : '-'}`}
        />
      </div>
      <div className="border-b border-gray-100 py-4">
        <JobDetailHeader
          title="Informasi tambahan"
          imageSrc="/assets/icons/ic_basic_information.svg"
        />

        <JobDetailInfo title="Benefit">
          <div className="flex flex-wrap items-center text-center">
            {jobDetailData?.benefitTags?.map((item) => (
              <div
                key={item.tagName}
                className="my-1 mr-1 flex rounded-md bg-secondary-green px-2 py-1"
              >
                <CheckRoundedIcon className="mr-1" />
                {item.tagName}
              </div>
            ))}
          </div>
        </JobDetailInfo>
      </div>
      <div className="border-b border-gray-100 py-4">
        <JobDetailHeader
          title="Media untuk ditampilkan di loker (video atau gambar)"
          imageSrc="/assets/icons/ic_album_add.svg"
        />
        <div className="flex">
          {jobDetailData &&
            jobDetailData.medias.length > 0 &&
            jobDetailData.medias.map((data) => {
              const mediaType = data?.mimeType.split('/')
              switch (mediaType[0]) {
                case 'video':
                  return (
                    <Link
                      href={data?.linkURL}
                      passHref
                      key={data?.link}
                      legacyBehavior
                    >
                      <a target="_black" className="cursor-pointer px-2 py-2">
                        <video className="h-[80px] w-[80px]">
                          <source src={data?.linkURL} />
                          <track kind="captions" />
                        </video>
                      </a>
                    </Link>
                  )
                case 'image':
                  return (
                    <Link
                      href={data?.linkURL}
                      key={data?.link}
                      passHref
                      legacyBehavior
                    >
                      <a target="_blank" className="px-2 py-2">
                        <Image
                          src={data?.linkURL}
                          alt="job media"
                          height={80}
                          width={80}
                        />
                      </a>
                    </Link>
                  )
                default:
                  return ''
              }
            })}
        </div>
      </div>
    </Drawer>
  )
}

export default Slider
