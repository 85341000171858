import Image from 'next/image'
import React from 'react'

import { expiredDateDiff } from '@utils/date-calculation'

type ExpiredDateBannerProps = {
  jobExpiredDate: string
}

const ExpiredDateBanner: React.FC<ExpiredDateBannerProps> = ({
  jobExpiredDate,
}: ExpiredDateBannerProps) => {
  if (!jobExpiredDate) {
    return null
  }
  const dateDiff = expiredDateDiff(new Date(jobExpiredDate))

  return (
    <div className="mb-4 flex items-center rounded-md border border-indigo-400 bg-indigo-50 p-2">
      <Image
        alt="icon"
        src="/assets/icons/ic_expired_warning.svg"
        width={24}
        height={24}
      />
      <p className="ml-2 text-xs">
        {`Lamaran ini akan kadaluarsa dalam ${dateDiff} hari`}
      </p>
    </div>
  )
}

export default ExpiredDateBanner
